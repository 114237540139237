"use client";

import { useCheckAuthAndRedirect } from "hooks/authentication";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Size } from "../../constants/enums/size";
import { Logo } from "../Logo";
import { LoginField } from "./LoginField";
import { RegisterField } from "./RegisterField";
import { Logout } from "./Logout";

export function AuthenticationComponent({
	name,
	message,
	redirectLocation,
	screen,
}: {
	name: string;
	message?: string;
	redirectLocation?: string;
	screen?: "login" | "register";
}) {
	const { user, credentials } = useSelector((state: any) => state.auth);
	const [type, setType] = useState<"login" | "register">(screen ?? "login");

	useCheckAuthAndRedirect({
		location: "login",
		redirect: redirectLocation ?? "home",
	});

	return (
		<div className="authentication-component">
			<Logo size={Size.LARGE} />

			<div className="logo__text">{name}</div>

			{user ? (
				<div className="authentication-component__content">
					Welcome {user?.username}!
					<Logout />
				</div>
			) : (
				<div className="authentication-component__content">
					{type === "login" ? (
						<LoginField
							toggle={() => {
								setType("register");
							}}
						/>
					) : (
						<RegisterField
							toggle={() => {
								setType("login");
							}}
						/>
					)}

					<a href="/forgot-password" className="link">
						Forgot password?
					</a>

					{message && <h3 className="body-caption centered">{message}</h3>}
				</div>
			)}
		</div>
	);
}
