import { Colors } from "ui/theme/Colors";
import { UniqueSellingPoint } from "./UniqueSellingPoint";
import { Ref } from "react";

export const UniqueSellingPoints = ({
	divRef,
}: {
	divRef?: Ref<HTMLDivElement | null>;
}) => {
	const usps = [
		{
			title: "Track your progress",
			description:
				"Keep track of your workouts and see how you're improving over time. Create or save other peoples workouts, track your progress, see your personal records, and way more.",
			image: "/images/screens/tracking_workout.png",
			side: "right",
			color: "#A74BFF",
		},
		{
			title: "Keep pushing yourself",
			description:
				"Participate in challenges, earn badges and become better than you were yesterday.",
			image: "/images/screens/challenges.png",
			side: "left",
			color: "#6D00FF",
		},
		{
			title: "Chat with your friends",
			description: "Sprinkle some motivation in your friends' lives.",
			image: "/images/screens/chat.png",
			side: "right",
			color: "#396afc",
		},
		{
			title: "Keep in touch with your friends",
			description:
				"Stay connected with your friends and see what they're up to. Keep each other accountable and motivated. Never workout alone again.",
			image: "/images/screens/checkin.png",
			side: "left",
			color: "#2948ff",
		},
		{
			title: "See your progress",
			description:
				"See your progress in simple and clear overviews. Track your workouts, see your personal records, and notice how you're improving over time.",
			image: "/images/screens/progress.png",
			side: "right",
			color: "#396afc",
		},
		{
			title: "And the best thing?",
			description:
				"It's completely free. No forced premium subscription, no hidden costs. Just you and your friends.",
			image: "/images/screens/profile.png",
			side: "left",
			color: "#6D00FF",
		},
	];

	return (
		<div ref={divRef} className="unique-selling-points">
			<div className="unique-selling-points__items">
				{usps.map((usp, index) => (
					<UniqueSellingPoint key={index} {...usp} index={index} />
				))}
			</div>
		</div>
	);
};
