import Image from "next/image";
import { Checkin } from "./index";
import { User } from "react-feather";

export const CheckinCard = ({ checkin }: { checkin: Checkin }) => (
	<div className="checkin-card">
		<div className="checkin-card__container">
			<div className="checkin-card__content">
				<div className="checkin-card__content__text">
					<div className="checkin-card__title">{checkin.title}</div>
				</div>
				<div className="checkin-card__avatar">
					<div className="checkin-card__avatar--image">
						{checkin.user.avatar ? (
							<Image
								src={checkin.user?.avatar}
								alt={checkin.user.username}
								width={60}
								height={60}
								unoptimized
							/>
						) : (
							<User strokeWidth={1} size={32} color="#000" />
						)}
					</div>
					<div className="checkin-card__user">@{checkin.user.username}</div>
				</div>
			</div>
			<div className="checkin-card__image">
				{checkin.image && (
					<Image
						src={checkin.image}
						alt={checkin.user.username}
						width={280}
						height={500}
						unoptimized
					/>
				)}
			</div>
		</div>
	</div>
);
