import classNames from "classnames";
import Image from "next/image";
import { ReactNode } from "react";

interface UniqueSellingPointProps {
	title: string;
	description: string;
	image: string;
	side: string;
	color: string;
	index: number;
}

export const UniqueSellingPoint = ({
	title,
	description,
	image,
	side,
	color,
	index,
}: UniqueSellingPointProps) => (
	<>
		{index > 0 ? (
			<div
				className="unique-selling-point__spacer"
				style={{
					background: `url(/images/svg/spacer_00${index + 1}.svg)`,
					backgroundSize: "cover",
					backgroundPosition: "center",
					backgroundRepeat: "no-repeat",
				}}
			/>
		) : null}
		<div className="unique-selling-point__parent" style={{ background: color }}>
			<div
				className={classNames("unique-selling-point", {
					"unique-selling-point--reverse": side === "right",
				})}
			>
				<div
					className={classNames("unique-selling-point__info-side", {
						"unique-selling-point__info-side--reverse": side === "right",
					})}
				>
					<div className="unique-selling-point__info-side__content">
						<div className="unique-selling-point__title">{title}</div>
						<div className="unique-selling-point__description">
							{description}
						</div>
					</div>
				</div>
				<div className="unique-selling-point__image-side">
					<div className="unique-selling-point__image">
						<Image src={image} alt={title} fill />
					</div>
				</div>
			</div>
		</div>
	</>
);
